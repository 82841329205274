import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import Header from './components/Header/Header';
import Navbar from './components/Navbar/Navbar';
import {I18nextProvider, initReactI18next} from "react-i18next";
import i18next from "i18next";
import common_ro from "./translations/ro/common.json";
import common_en from "./translations/en/common.json";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import Content from './components/Content/Content';
import About from './components/About/About';
import Games from './components/Games/Games';
import Contact from './components/Contact/Contact';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
i18next
  .use(initReactI18next)
  .init({
    interpolation: { escapeValue: false },  
    lng: 'ro',
      resources: {
          en: {
              common: common_en
          },
          ro: {
              common: common_ro
          },
      },
  })

function App() {
  return (
    <Router>
      <I18nextProvider i18n={i18next}>
        <div className="App">
          <Header/>
          <Navbar/>
          <Content>
            <Routes>
              <Route path='/' element={<Navigate to="/contact"/>}/>
              <Route path='/about' Component={About}/>
              <Route path='/games' Component={Games}/>
              <Route path='/contact' Component={Contact}/>
              <Route path='/privacyPolicy/slanaMariane' element={<PrivacyPolicy gameName='Slana Mariane' contactEmail='adrian.vat@deviant.gg' gameType='Freemium' companyName='Deviant' usesAdMob usesUnity usesGooglePlayServices/>}/>
              <Route path='/privacyPolicy/hogPop' element={<PrivacyPolicy gameName='Pig Pop' contactEmail='adrian.vat@deviant.gg' gameType='Freemium' companyName='Deviant' usesAdMob usesUnity usesGooglePlayServices/>}/>
            </Routes>
          </Content>
        </div>
      </I18nextProvider>
    </Router>
  )
}

export default App

import LanguageSelector from "../LanguageSelector/LanguageSelector"
import Logo from "../Logo/Logo"
import "./Header.css"

export type HeaderProps = {

}

const Header = (props: HeaderProps) => {
    return (
        <div className="Header_container">
            <Logo/>
        </div>
    )
}

export default Header
import "./Games.css"

export type GamesProps = {

}

const Games = (props: GamesProps) => {
    return (
        <div className="Games_container">
            {renderGameItem("Slana Mariane", "slanaMarianeIcon.png", "https://play.google.com/store/apps/details?id=gg.deviant.slana.mariane", "https://apps.apple.com/ro/app/slana-mariane/id6447067597", "https://www.deviant.gg/privacyPolicy/slanaMariane")}
            {renderGameItem("Pig Pop", "pigPopIcon.png", "https://play.google.com/store/apps/details?id=gg.deviant.hog.pop", "https://apps.apple.com/ro/app/pig-pop/id6499260876", "https://www.deviant.gg/privacyPolicy/hogPop")}
        </div>
    )
}

function renderGameItem(title: string, imageSrc: string, androidUrl?: string, iosUrl?: string, privacyPolicyUrl?: string){
    return (
        <div className="Games_game-item-container">
            <img className="Games_game-item-icon" src={imageSrc}/>
            <p>{title}</p>
            <div>
                {androidUrl && <a href={androidUrl} target="_blank"><img className="Games_game-item-store-icon" src="googlePlayIcon.png"/></a>}
                {iosUrl && <a href={iosUrl} target="_blank"><img className="Games_game-item-store-icon" src="appStoreIcon.png"/></a>}
            </div>
            {privacyPolicyUrl && <a href={privacyPolicyUrl} className="Games_game-item-privacy-policy">Privacy Policy</a>}
        </div>
    )
}

export default Games
import { ReactElement } from "react"
import "./Content.css"

export type ContentProps = {
    children: ReactElement
}

const Content = (props: ContentProps) => {
    return (
        <div className="Content_container">
            {props.children}
        </div>
    )
}

export default Content
import { useTranslation } from "react-i18next"
import "./LanguageSelector.css"

export type LanguageSelectorProps = {

}

const LanguageSelector = (props: LanguageSelectorProps) => {
    const{t, i18n} = useTranslation("common");

    return (
        <span className="LanguageSelector_container">
            <img
                src="https://flagcdn.com/60x45/ro.png"
                srcSet="https://flagcdn.com/120x90/ro.png 2x,
                    https://flagcdn.com/180x135/ro.png 3x"
                width="60"
                height="40"
                alt="RO"
                onPointerDown={() => i18n.changeLanguage("ro")}
                />
            <img
                src="https://flagcdn.com/60x45/gb.png"
                srcSet="https://flagcdn.com/120x90/gb.png 2x,
                    https://flagcdn.com/180x135/gb.png 3x"
                width="60"
                height="40"
                alt="EN"
                onPointerDown={() => i18n.changeLanguage("en")}
                />
        </span>
    )
}

export default LanguageSelector
import "./Logo.css"
import { ReactComponent as LogoSvg } from "../../media/logo.svg"

export type LogoProps = {

}

const Logo = (props: LogoProps) => {
    return (
        <div className="Logo_container">
            <LogoSvg width={300} height={150}/>
        </div>
    )
}

export default Logo
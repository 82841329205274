import { useTranslation } from "react-i18next"
import "./About.css"

export type AboutProps = {

}

const About = (props: AboutProps) => {
    const {t, i18n} = useTranslation("common");

    return (
        <div className="About_container">
            <h2>{t("about.title")}</h2>
            <br/>
            <p>{t("about.paragraph1")}</p>
            <br/>
            <p>{t("about.paragraph2")}</p>
            <br/>
            <p>{t("about.paragraph3")}</p>
            <br/>
            <p>{t("about.paragraph4")}</p>
            <br/>
            <p>{t("about.paragraph5")}</p>
            <br/>
            <br/>
        </div>
    )
}

export default About
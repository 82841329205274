import { Trans, useTranslation } from "react-i18next";
import "./Contact.css"

export type ContactProps = {

}

const Contact = (props: ContactProps) => {
    const {t, i18n} = useTranslation("common");

    return (
        <div className="Contact_container">
            <h2>{t("contact.title")}</h2>
            <h4>{t("contact.subtitle")}</h4>
            <br/>
            <h5>{t("contact.generalInquiriesTitle")}</h5>
            <p>
                <Trans t={t} i18nKey="contact.generalInquiriesContent">
                    Dacă aveți întrebări generale despre compania noastră sau jocurile noastre, vă rugăm să ne contactați la adresa <a style={{textDecoration: "underline", fontWeight: "bold"}} href="mailto:adrian.vat@deviant.gg">adrian.vat@deviant.gg</a> \nVom face tot posibilul să vă răspundem cât mai curând posibil.
                </Trans>
            </p>
            <br/>
            <h5>{t("contact.supportTitle")}</h5>
            <p>
                <Trans t={t} i18nKey="contact.supportContent">
                    Dacă întâmpinați probleme cu unul dintre jocurile noastre, vă rugăm să contactați echipa noastră de asistență la adresa <a style={{textDecoration: "underline", fontWeight: "bold"}} href="mailto:adrian.vat@deviant.gg">adrian.vat@deviant.gg</a> Vom face tot ce putem pentru a vă ajuta să rezolvați problema și să vă întoarceți la joc cât mai curând posibil.
                </Trans>
            </p>
            <br/>
            <h5>{t("contact.socialMediaTitle")}</h5>
            <p>
                <Trans t={t} i18nKey="contact.socialMediaContent">
                </Trans>
            </p>
            <br/>
            <p>{t("contact.footer")}</p>
            <br/>
            <br/>
        </div>
    )
}

export default Contact
import { TabPanelUnstyled } from "@mui/base"
import { Tabs } from "@mui/material"
import Box from "@mui/material/Box"
import Tab from "@mui/material/Tab"
import { useState } from "react"
import "./Navbar.css"
import {useTranslation} from "react-i18next";
import { SocialIcon } from 'react-social-icons';
import {
    Link,
    useLocation
  } from "react-router-dom";
import LanguageSelector from "../LanguageSelector/LanguageSelector"

export type NavbarProps = {

}

const Navbar = (props: NavbarProps) => {
    const {t, i18n} = useTranslation("common");
    const location = useLocation();
    return (
        <div className="Navbar_container">
            <LanguageSelector/>
            {renderNavButtons()}
            {renderSocialMediaButtons()}
        </div>
    )

    function renderNavButtons(){
        return (
            <div className="Navbar_nav-buttons-container">
                <Link to="/about">
                    <div className={location.pathname == "/about" ? "Navbar_nav-button Navbar_nav-button-selected" : "Navbar_nav-button"}>
                        {t("navbar.about")}
                    </div>
                </Link> 
                <Link to="/games">
                    <div className={location.pathname == "/games" ? "Navbar_nav-button Navbar_nav-button-selected" : "Navbar_nav-button"}>
                        {t("navbar.games")}
                    </div>
                </Link>            
                <Link to="/contact">
                    <div className={location.pathname == "/contact" ? "Navbar_nav-button Navbar_nav-button-selected" : "Navbar_nav-button"}>
                        {t("navbar.contact")}
                    </div>
                </Link>   
            </div>
        )
    }

    function renderSocialMediaButtons(){
        return (
            <div className="Navbar_social-media-buttons-container">
                <SocialIcon target="_blank" className="Navbar_social-icon" url="https://www.facebook.com/profile.php?id=100091274718631" style={{width: '40px', height: '40px'}}/>
                <SocialIcon target="_blank" className="Navbar_social-icon" url="https://www.instagram.com/deviant_gg/" style={{width: '40px', height: '40px'}}/>
                <SocialIcon target="_blank" className="Navbar_social-icon" url="https://www.tiktok.com/@userwsb1y4wcj7" style={{width: '45px', height: '45px'}}/>
            </div>
        )
    }
}

export default Navbar